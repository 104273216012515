div.sketch-controls
{
    text-align: center;
    cursor: pointer;
    margin-bottom: 1rem;
}

div.sketch-controls button.btn
{
    /* height: 1rem; */
    border-radius: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    min-width: 0px;
    /* width: 100%; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

div.brush
{
    position: relative;
}
div.brush div
{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.canvas-wrapper
{
    border: 1px dashed #aaa;
}