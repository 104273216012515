h5
{
    font-family: orpheuspro, serif;
    font-weight: 700;
    font-style: normal;
    text-transform: capitalize;
    margin-top: 1rem;
    /* text-align: center; */
    /* font-size: 0.9rem; */
}

div.moodboard-text
{
    font-weight: 100;
    text-transform: capitalize;
    font-size: 0.9rem;
    /* text-align: center; */
    padding: 0.2rem;
}
div.moodboard-text>div
{
    
    padding: 0.3rem;
    border: 1px solid transparent;
    border-radius: 1.5rem;
}

div.moodboard-text.selected-text-option>div
{
    color: white;
    /* background-color: rgba(0,0,0,0.7); */
    /* border: 1px solid black; */
    padding: 0.3rem;
    border-radius: 1.5rem;

}

div.moodboard-text p
{
    margin-bottom: 0px;
    position: relative;
}

/* .text-moodboard-left
{
    height: 100%;
} */

.text-moodboard-right
{
    padding: 1rem;
    padding-left: 2rem;
    border: 1px solid black;
}

.text-moodboard-left input
{
    /* border: none; */
    border: 2px solid black;
    border-radius: 0;
    padding: 1rem;
    /* font-size: 1.5rem; */
    margin-top: 2rem;

}

.text-moodboard-left h6
{
    text-align: center;

}


.text-moodboard-left>div
{
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; */
    /* transform: translate(0, -25%); */
}

.text-moodboard-left .selected-options
{
    margin-top: 2rem;
    position: relative;
}

.text-moodboard-left .selected-options .moodboard-text
{
    /* margin-top: 2rem; */
    /* margin-bottom: 1rem; */

}

.text-moodboard-left .selected-options
{
    /* margin-top: 2rem; */
    margin-bottom: 2rem;
}

.text-moodboard-left .selected-options p
{
    font-size: 1.5rem;
    text-align: center;
    padding-left: 1.5rem;
    color: black;
    /* border: 1px solid black; */
    border-radius: 1rem;
    width: max-content;
    padding-right: 1rem;
    width: 100%;

}

.text-moodboard-right .moodboard-text img
{
    /* opacity: 0.5; */
}

.text-moodboard-left .selected-options img
{
    /* filter: grayscale(1); */
    opacity: 0;
}


.visual-moodboard-option.selected
{
    /* background-color: rgba(0,50,0,0.4); */
}

@media (min-width: 992px) { 
    .visual-moodboard-option img
    {
        opacity: 0.7;
    }

    .visual-moodboard-option:hover img
    {
        opacity: 1;
    }

 }


.visual-moodboard-option.selected img
{
    transform: scale(0.9);
    opacity: 1;
}