#instructions
{
    padding: 2rem;
}

#instructions .vcenter-inner form
{
    padding: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 1.2rem;

}

@media (min-width: 992px) { 
    #instructions
    {
        height: 100vh;
        position: relative
    }

    #instructions .vcenter-outer
    {
        position: relative
    }

    #instructions .vcenter-inner
    {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: max-content
    }
 }

#instructions .btn
{
    margin-top: 1rem;
    
}

#instructions h2
{
    margin-bottom: 1rem;
}

#instructions h6
{
    font-weight: 600;
    font-size: 1.3rem;
}

#instructions .center
{
    text-align: center;
}

#instructions .pointer
{
    cursor: pointer;
}

#instructions-modal
{
    font-weight: 400;
    
}

#instructions-modal .modal-content
{
    font-weight: 400;
    padding: 1.5rem;
}

#instructions-modal .modal-content .btn
{
    
    /* margin-left: 8.33%; */
    margin-top: 0;
}

#instructions-modal .modal-footer
{
    
    border-top-color: transparent;
}

#instructions-modal .modal-header
{
    
    border-bottom-color: transparent;
}


#instructions .pointer:hover
{
    text-decoration: underline;
}

.password-form-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .password-form-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .password-form-exit {
    opacity: 1;
  }
  .password-form-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  