


#navbar-dark, #navbar-light
{
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  padding: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
}

#navbar-dark .nav-link, #navbar-light .nav-link
{
  font-family: sofia-pro, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 0.5rem; 
  padding-bottom: 0.5rem;
}


.nav-link:focus,.nav-link:focus
{
  outline: none;
  box-shadow: none;
}

#navbar-dark .navbar-brand, #navbar-light .navbar-brand
{
  font-family: bigmoore, serif;
  font-size: 1.7rem;
  font-weight: 600;
  padding-top: 0rem;
  padding-bottom: 0rem;

}

#navbar-dark
{
  background: black;
}


#navbar-light
{
  background: transparent;
}

#navbar-dark .nav-link, #navbar-dark .navbar-brand
{
  color: white;
}


#navbar-light .nav-link, #navbar-light .navbar-brand
{
  color: white;
}