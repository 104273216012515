.canvas-tile-wrapper {
    padding: 0.5rem;
    margin-bottom: 30px;
}

#canvas {
    padding: 1rem;
}

#canvas .edit-title a {
    /* display: none; */
    color: black;
    font-weight: 300;
}

#canvas .edit-story a {
    /* display: none; */
    color: black;
    font-weight: 300;
}

#canvas a {
    color: black;
}

#canvas .canvas-name:hover .edit-title a {
    display: block;
}

#canvas .canvas-story:hover .edit-story a {
    display: block;
}

.canvas-tile-wrapper.image-tile {
    border-radius: 0.5rem;
}

.canvas-tile-wrapper .sketch {
    padding: 0px;
    /* max-height: 300px; */
}

.canvas-tile-wrapper.image-tile>div {
    padding: 0px;
}

.canvas-tile-wrapper.image-tile .canvas-tile {
    margin-bottom: 0px;
}

.canvas-tile-wrapper.image-tile img {
    height: 100%;
    width: auto;
    position: absolute;
    /* top: -9999px;
    left: -9999px; 
    right: -9999px;
    bottom: -9999px;*/
    margin: auto;
}

#canvas .canvas-tile .img-wrapper .option {
    opacity: 0;
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

#canvas .canvas-tile .img-wrapper:hover .option {
    opacity: 1;
}

.canvas-tile {
    min-height: 300px;
    border-radius: 0.5rem;
    /* margin-bottom: 1rem; */
    border: 1px solid #ddd;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    height: 100%;
    padding-top: 2rem;
    background-color: #fafafa;
    padding: 0rem;
}

.canvas-tile p {
    font-weight: 200;
}

.canvas-tile:hover {
    background-color: #eeeeee;
}

.canvas-tile h5 {
    text-align: center;
    font-size: 1.1rem;
}

.canvas-tile p.option-text {
    text-align: center;
    font-size: 0.85rem;
}

.canvas-tile>div {
    height: 100%;
    position: relative;
    /* transform: translate(0, -50%); */
}

.canvas-tile {
    overflow: hidden;
}

#canvas .comment-tile {
    min-height: 300px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    height: min-content;
    overflow: hidden;
    /* background-color: #eee; */
    transition: all 0.2s ease-in-out;
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-weight: 400;
}

#canvas .reply-to {
    background-color: #eee;
    /* padding: 0px; */
    opacity: 0.8;
}

#canvas .reply-to .canvas-tile {
    padding: 0px;
}

#canvas .reply-to .canvas-tile img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: auto;
}

#canvas .comment-tile .btn {
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

#canvas .comment-tile p {
    font-weight: 200;
    font-size: 1.1rem;
    margin-top: 0rem;
    padding: 0rem;
    margin-bottom: 0rem;
}

#canvas .quill p {
    font-weight: normal;
    font-size: 1rem;
    padding: 0rem;
}

#canvas .comment-tile-render {
    overflow: hidden;
    background-color: white;
}

#canvas .user-profile {
    background-color: transparent;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

#canvas .user-profile img {
    height: 2rem;
    border-radius: 2rem;
    width: auto;
    /* margin-right: 1rem; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
}

#canvas .user-profile .name {
    font-weight: 200;
    margin-bottom: 0px;
    margin-left: 2rem;
}

#canvas .user-profile .time {
    font-weight: 200;
    font-size: 0.85rem;
    color: #888888;
    margin-bottom: 0rem;
    margin-left: 2rem;
    margin-top: -0.2rem;
}

#canvas .comment-tile img, #canvas .canvas-tile .img {
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

#canvas .add-content-tile {
    min-height: 300px;
    /* background-color: rgba(255,255,255,0.3); */
    border: 1px solid #ddd;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#canvas .add-content-tile>div {
    margin-top: 50%;
    transform: translate(0, -50%);
}

@media (min-width: 992px) {
    #canvas .edit-title a {
        display: none;
    }
    #canvas .edit-story a {
        display: none;
    }
    #canvas {
        padding: 4rem;
    }
    #canvas .tile-controls {
        opacity: 0;
    }
    #canvas .canvas-tile-wrapper:hover .tile-controls {
        opacity: 1;
    }
    #canvas .canvas-tile-wrapper:active .tile-controls {
        opacity: 1;
    }
    #canvas .comment-tile .img-wrapper .maximizeButton {
        opacity: 0;
    }
    #canvas .comment-tile .img-wrapper:hover .maximizeButton {
        opacity: 1;
    }
}

#canvas .tile-controls {
    color: #666;
    font-weight: 100;
    text-align: center;
}

#canvas .tile-controls a {
    text-align: right;
    cursor: pointer;
}

#canvas .tile-controls a:hover {
    color: black;
}

#canvas .tile-controls a:active {
    color: black;
}

#canvas .reply-to {
    min-height: 300px;
    overflow: hidden;
}

#canvas .reply-to h6 {
    font-family: 'rand regular';
    /* font-weight: 700; */
    color: black;
    padding: 1rem;
    font-size: 1.2rem;
}

#canvas .reply-to .canvas-tile {
    height: auto;
}

#canvas .next-steps p {
    font-weight: 200;
    font-size: 0.85rem;
}

#canvas .next-steps .btn {
    font-weight: 200;
    font-size: 0.85rem;
    padding: 0.5rem;
}

#canvas .canvas-sketch {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: transparent;
}

#canvas .comment-tile .img-wrapper {
    overflow: hidden;
}

.document-icon {
    width: 100px !important;
    height: 100px !important;
}

.document-name {
    margin-top: 20px;
    font-size: 20px;
    color: #000;
    text-align: center;
    width: 90%;
    word-break: break-all;
    white-space: break-spaces;
}

.tile-content-wrapper {
    padding: 2rem;
    width: 100%;
}

.tile-content-wrapper .deleteButton {
    opacity: 0;
}

.tile-content-wrapper:hover .deleteButton {
    opacity: 1;
}

.tile-content-wrapper:active .deleteButton {
    opacity: 1;
}

.visual-moodboard-tile .delete-button-image {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: sticky;
    top: 85%;
    left: 85%;
    opacity: 0;
}

.visual-moodboard-tile:hover .delete-button-image {
    opacity: 1;
    position: sticky;
    top: 85%;
    left: 85%;
}

.visual-moodboard-tile:active .delete-button-image {
    opacity: 1;
    position: sticky;
    top: 85%;
    left: 85%;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    position: inherit;
    height: inherit !important;
}

.react-pdf__Page {
    display: flex;
    justify-content: center;
}

.react-pdf__Page__annotations, .annotationLayer {
    width: 0;
}

#canvas .canvas-tile .img-wrapper .prev-page {
    width: 2rem;
    height: 2rem;
    position: absolute;
    z-index: 1;
    opacity: 0;
    margin: auto;
    bottom: 20px;
    left: 0px;
    top: 0px;
    margin-left: 20px;
    cursor: pointer;
}

#canvas .canvas-tile .img-wrapper:hover .prev-page {
    opacity: 1;
}

#canvas .canvas-tile .img-wrapper .next-page {
    width: 2rem;
    height: 2rem;
    /* background-color: rgb(0, 0, 0); */
    position: absolute;
    z-index: 1;
    opacity: 0;
    margin: auto;
    /* top: 50%; */
    bottom: 20px;
    right: 20px;
    /* margin-bottom: 20px; */
    /* margin-bottom: 10px; */
    top: 0px;
    cursor: pointer;
}

#canvas .canvas-tile .img-wrapper:hover .next-page {
    opacity: 1;
}

#canvas .canvas-tile .img-wrapper .maximize {
    width: 2rem;
    height: 2rem;
    /* background-color: rgb(0, 0, 0); */
    position: absolute;
    z-index: 1;
    opacity: 0;
    margin: auto;
    top: 50%;
    bottom: 20px;
    right: 20px;
    /* margin-bottom: 20px; */
    margin-bottom: 10px;
    cursor: pointer;
}

#canvas .canvas-tile .img-wrapper:hover .maximize {
    opacity: 1;
}

.comment-container:hover .comment-features {
    opacity: 1;
}

.comment-container .comment-features {
    opacity: 0;
}

.quill-nonedit .ql-toolbar {
    display: none;
}

.quill-edit .ql-container {
    border: none !important;
}

.quill-nonedit .ql-container {
    border: none !important;
}

.ql-editor {
    font-family: 'rand regular';
    font-size: 1rem;
}

#waiting-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#waiting-button .fade-text {
    position: absolute;
    top: 0;
    bottom: 0;
}

#waiting-button .fade-text:nth-child(1) {
    animation-name: fade-text-alternate;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate-reverse;
}

#waiting-button .fade-text:nth-child(2) {
    animation-name: fade-text-alternate;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate;
}

#waiting-button .spacer {
    display: flex;
    visibility: hidden;
}

@keyframes fade-text-alternate {
    0%, 50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.admin-comment-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
}

.normal-comment-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
}

.comment-tile .line-clamp {
    --lh: calc(1.5 * 1.1rem);
    --max-lines: 7;
    position: relative;
    max-height: calc(var(--lh)*var(--max-lines));
    overflow: hidden;
    padding-right: 1rem;
    text-overflow: ellipsis;
    /* display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}

.comment-tile .line-clamp::before {
    position: absolute;
    /* content: "..."; */
    inset-block-start: 0;
    inset-inline-end: 0;
}

.comment-tile .line-clamp::after {
    content: "...";
    position: absolute;
    inset-inline-end: 0;
    width: 1rem;
    height: 1rem;
    text-overflow: ellipsis;
}

.next-prev_buttons_container {
    position: absolute;
    top: 0; right: 0; left: 0; bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.next-prev_buttons_container div {
    display: flex;
    align-items: center;
    opacity: 0;
    height: 60%;
    width: 12rem;
    cursor: pointer;
    font-size: 8rem;
    font-weight: bold;
    color: black;
}

.next-prev_buttons_container div.show {
    opacity: 1;
    background: #b3b3b370;
}

.next-prev_buttons_container .prev {
    justify-content: center;
}

.next-prev_buttons_container .next {
    justify-content: center;
}