@media (min-width: 300px){
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    font-size: 10px;
  }
}

@media (min-width: 600px){
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    font-size: 14px;
  }
}

.MuiStepButton-root{
  margin:0!important;
  padding:0!important;
}

.MuiStepper-horizontal{
  padding:0!important;
}

.MuiStep-alternativeLabel{
  padding:0!important;
}

.MuiStepIcon-text{
  display:none!important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #000!important;
}

.MuiStepIcon-root{
  border: 0.5px solid #000;
  border-radius: 50%;
  color: #fff!important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel{
  font-family: sofia-pro;
}