div.option
{
    margin-top: 1rem;
    margin-bottom: 1rem;

}

/* .search-upload-option img
{
    max-height: 200px;
} */

#show-me-more-btn
{
    margin-top: 0px;
    color: black;
    background-color: white;
    border-radius: 0px;
    border: 1px solid black;
    min-width: 100px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}