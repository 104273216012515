#story .title
{
    text-align: center;
    font-weight: 200;
    color: grey;
    margin-bottom: 0rem;
}

#story input
{
    text-align: center;
    font-size: 2rem;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid black;
    margin-bottom: 2rem;
}

#story textarea
{
    margin-top: 1rem;
    border-radius: 0rem;
    border: 2px solid black;
}

#story .ql-editor
{
    font-weight: 300;
    font-size: 1rem;
}

#story .ql-editor strong
{
    font-weight: 600;
    font-size: 1rem;
}

#story .quill
{
    min-height: 250px;
}