/************************************************************************************/
/************************************************************************************/

/* HERO */
#hero{
  background-position : bottom; /* Center the image */
  background-repeat   : no-repeat; /* Do not repeat the image */
  background-size     : cover; /* Resize the background image to cover the entire container */
  min-height          : 100vh;
  font-family         : sofia-pro, sans-serif;
  display: flex;
}

#hero h1 {
color           :#FFFFFF;
line-height     : 1.5rem;
font-size       : 1.7rem;
font-weight     : bold;
max-width       : 486px;
padding-bottom  : 1rem;
margin-bottom   : 1rem;
}
#hero h6{
color           :#FFFFFF;
font-size       : 0.9rem;
font-weight     : 400;
padding-bottom  : 1rem;
line-height     : 1.3rem;
}

#hero .btn{
  background-color    : #FFFFFF;
  box-shadow          : 2px 2px 6px rgba(0, 0, 0, 0.16);
  color               : #000000;
  border              : none;
  border-radius       : 0.25rem;
  
  margin-left         : 0;
  margin-top          : 2rem;
  padding-top         : 0.8rem;
  padding-bottom      : 0.8rem;
  padding-left        : 2.5rem;
  padding-right       : 2.5rem;
  
  font-size           : 0.95rem;
  font-weight         : 600;
  box-shadow          : 2px 2px 6px rgba(0,0,0,.16);
  min-width           : 15%;
}

#hero .container
{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  top: -15%;
position: relative;
}

#hero .container-fluid
{
  margin-top    : auto;
  margin-bottom : auto;
  margin-left   : auto;
  margin-right  : auto;
  display       : block;

}

@media (min-width: 300px){
#hero .container-fluid
{
  margin-top: 50%;
}

#hero .container
{
  margin-top: 50%;
}

  #hero h1{
      /* font-size : 40px; */
      max-width : 100%;
      text-align: center;
  }
  #hero h6{
      font-size : 0.9rem;
      font-weight: 400;
      text-align: center;
  }

  #hero .btn{
      margin-left   : auto;
      margin-right  : auto;
      display: block;

  }
}


@media (min-width: 768px){
  #hero .container
  {
    margin-top: 20%;
  }

  #hero .container-fluid
  {
    margin-top: 20%;
  }
  #hero h1{
      font-size     : 2.3rem;
      max-width     : 100%;
  }
  #hero h6{
      font-size: 1rem;
  }

  #hero .btn{
      font-size: 1rem;
  }
}

@media (min-width: 1000px){
#hero .container
{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: unset;
    margin-right: unset;
}

#hero .container-fluid
{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: unset;
    margin-right: unset;
}
  #hero h1 {
      color             :#FFFFFF;
      line-height       : 64px;
      font-size         : 3.3rem;
      font-weight       : bold;
      max-width         : 486px;
      text-align        : left;
  }

  #hero h6 {
      font-size           : 1.2rem;
      text-align          : left;
      /* max-width       : 38rem; */
      padding-left    : 0;
      line-height     : 1.8rem;
    }
      
  #hero .btn{
    margin-top        : 2rem;
    margin-left: 0;
      font-size: 1.2rem;
  }
}

/************************************************************************************/
/************************************************************************************/

/* TEXT_ICON_SECTION */
.text-icon-section{
  padding-top         : 40px;
  padding-bottom      : 40px;
  /* min-height          :100vh; */
  background-color    : #EFEFEF;
  font-family         : sofia-pro, sans-serif;
}

.text-icon-section .container{
  /* min-height      : 100vh; */
  display         : flex;
  flex-direction  : column;
  justify-content : center;
}

.text-icon-section .item-container{
 display          : flex;
 justify-content  : space-between;
 width            : 100%;    
 flex-wrap        : wrap;
}

.text-icon-section .item{
 max-width        : 100%;
 text-align       : center;
 height           : fit-content;
 /* margin           : 1rem; */
 padding          : 1rem;
}

.text-icon-section img{
  padding          : 0rem;
 }

.text-icon-section h2{
  font-family         : sofia-pro, sans-serif;
  text-align       : center;
  font-size        : 1.6rem;
  margin-bottom    : 70px;
  font-weight      : bold;
}

.text-icon-section h4{
 font-size        : 1.1rem;
 font-weight      : bold;
 margin-top       : 40px;

}

.text-icon-section p{
 font-size        : 0.9rem;
 line-height      : 20px;
 font-weight      : normal;
}

@media (min-width: 600px){


  .text-icon-section h4{
      font-size        : 1.2rem;
      margin-bottom    : 0rem;
  }

  .text-icon-section p{
      font-size        : 1rem;
      margin-top       : 0px;
      line-height      : normal;
      font-weight      : 400;

     }
}
@media (min-width: 1000px){
 
  .text-icon-section h2{
      font-family      : sofia-pro, sans-serif;
      text-align       : center;
      font-size        : 2rem;
      margin-bottom    : 70px;
      font-weight      : bold;
  }

  .text-icon-section h4{
      font-size        : 1.2rem;
      margin-bottom    : 0rem;
  }

  .text-icon-section p{
      font-size        : 1rem;
      margin-top       : 0px;
      line-height      : normal;
      font-weight      : 400;
  }

  .text-icon-section img{
    padding          : 1rem;
   }
}


/************************************************************************************/
/************************************************************************************/

/* TESTIMONIALS */
#testimonials
{
  min-height  : 100vh;
  /* font: normal 400 1em sofia-pro, sans-serif;; */
  text-align  : center;
}

#testimonials .container{
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
}


#testimonials .container-fluid{
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
}

#testimonials h3{
  font-family     : sofia-pro, sans-serif;
  /* font-style      : italic; */
  font-weight     : normal;
  font-size       : 1.7rem;
  line-height     : 43px;
}

#testimonials h3::before{
  font-family     : bureau-grot, sans-serif;
}

#testimonials h3::before {
  content: "\201D";
  margin-right: 0.5rem;
  padding-top: 1rem;
  transform: rotate(180deg) translateY(0);
  display: inline-block;
  color: #434267;
  font-family: bureau-grot, sans-serif;
  font-size: 3rem;
  line-height: 0;
  z-index: 0;
  
}

#testimonials h3::after {
  content: "\201D";
  position: absolute;
  margin-left: 10px;
  transform: translateY(50px);
  display: inline-block;
  color: #434267;
  font-family: bureau-grot, sans-serif;
  font-size: 3rem;
  line-height: 0;
  margin-top: -0.5rem;
  
}

@media (min-width: 300px){
  #testimonials h3{
      font-family     : sofia-pro, sans-serif;
      /* font-style    : italic; */
      font-weight   : normal;
      font-size     : 1.7rem;
      line-height   : 43px;
  }
}

@media (min-width: 600px){
 
  #testimonials h3{
      font-family     : sofia-pro, sans-serif;
      /* font-style    : italic; */
      font-weight   : normal;
      font-size     : 2.5rem;
      line-height   : 3.5rem;
  }
}

/************************************************************************************/
/************************************************************************************/


/* FULL_WIDTH_IMAGE */
.full-width-image{
  background-position     : bottom; /* Center the image */
  background-repeat       : no-repeat; /* Do not repeat the image */
  background-size         : cover; /* Resize the background image to cover the entire container */
  min-height              : 100vh;
  font-family             : "sofia-pro";
  
}

.full-width-image .section4-container{
  text-align          : center;
  padding-top         : 30px;
  background-color    : rgba(0,0,0,0.5);
  min-height          : 100vh;
  padding-left        : 10px;
  padding-right       : 10px;
}

.full-width-image h1{
  color           :#FFFFFF;
  font-weight     : bold;
  margin-bottom   : 1.5rem;
  font-size       : 0.9rem;
}

.full-width-image h6{
  color       :#FFFFFF;
  max-width   : 80%;
  margin      : auto;
  font-style  : normal;
  font-weight : 500;
  font-size   : 24px;
  line-height : 143.5%;
  /* or 34px */

  text-align  : center;
}

@media (min-width: 300px){


  .full-width-image h1{
      font-size       : 1.6rem;
  }
  .full-width-image h6{
    font-size: 0.9rem;
  }
}


@media (min-width: 600px){


  .full-width-image h1{
      font-size       : 1.6rem;
  }
  .full-width-image h6{
    font-size: 1rem;
  }
}

@media (min-width: 1000px){
  
  .full-width-image h6{
    max-width   : 40%;
  }
  .full-width-image h1{
    font-size: 2rem;
  }
  .full-width-image h6{
    font-size: 1rem;
  }
}

/************************************************************************************/
/************************************************************************************/
/* Footer */
#footer{
  background-position     : bottom; /* Center the image */
  background-repeat       : no-repeat; /* Do not repeat the image */
  background-size         : cover; /* Resize the background image to cover the entire container */
  min-height              : 100vh;
  font-family             : "sofia-pro";
}

#footer .container{
  text-align              : center;
  justify-content         : flex-start;
  padding-top             : 30px;
}

#footer .btn{
  background-color        : #FFFFFF;
  color                   : #000000;
  border                  : none;
  border-radius           : 0;
  margin-top              : 30px;
  text-transform          : capitalize;
  padding-top             :1rem;
  padding                 : bottom 1rem;
  font-size               : 20px;
}

#footer h1{
  font-size       : 64px;
  font-weight     : bold;
  color           : #FFFFFF;
  margin-top      : 50px;
  margin-top      : 72px;
  margin-bottom   : 35px;
}


@media (min-width: 300px){

  #footer h1{
    font-size: 2rem;
  }
}

@media (min-width: 600px){


  #footer h1{
    font-size: 3rem;
  }
}

@media (min-width: 1000px){

  #footer h1{
    font-size: 64px;
  }
}

/************************************************************************************/
/************************************************************************************/

/* FOOTER */
footer
{
font: normal 400 1em sofia-pro, sans-serif;;
padding-top: 3rem;
padding-bottom: 3rem;
background-color: #F6F6F6;
font-weight: 400;
}

footer .contact
{
margin-top: 1em;
}

footer a, a:hover
{
color: black;
}


footer .links ul
{
font: normal 400 1em sofia-pro, sans-serif;;
list-style: none;
padding-left: 0px;

}

footer .links ul li:first-of-type
{
font-weight: 900;
font-size: 1.1rem;
margin-bottom: 1rem;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
footer.col-12{
  padding-left: 5rem;
  padding-right: 5rem;
} 
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
footer{
  padding-top: 5rem;
  padding-bottom: 5rem;
} 
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
footer{
  padding-top: 5rem;
  padding-bottom: 5rem;
} 

}

/* // 2K or 4K (tablets, 768px and up) */
@media (min-width: 2500px) { 

footer
{
  font-size: 1.2rem;
}

footer .links ul li:first-of-type
{
  font-weight: 900;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
}

/************************************************************************************/
/************************************************************************************/
/* CTA */

.section-cta button
{
width: 100%;
background-color: white;
padding: 0.8em;
font: normal 400 1em sofia-pro, sans-serif;
font-size: 1.2rem;
font-weight: 600;
color: black;
border: none;
box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
margin-top: 4rem;
margin-bottom: 4rem;

}

.section-cta
{
  background-color: #E7E7E7;
}