#splash-auth {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  top: 0; bottom: 0; left: 0; right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.699);
}

.spinner-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner-container .spinner-border-lg {
  height: 8rem;
  width: 8rem;
  border-width: .6rem;
  margin-bottom: 1rem;
}

.spinner-container p {
  font-size: 2rem;
  color: white;
}

.tos-container {
  height: 100%;
  width: 100%;
  max-height: 850px;
  max-width: 768px;
  align-self: center;
  background-color: white;
  overflow: auto;
  padding: 2rem 2rem;
  position: relative;
}

.tos-container p .bold{
  font-weight: bold;
}

.pre-agreement-container {
  min-height: 100%;
  min-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  
  p {
    max-width: 400px !important;
  }

  h2:first-of-type {
    color: var(--theme-color-1);
    margin-bottom: 3.5rem;
  }
}

.tos-button {
  display: block;
  height: max-content;
  margin: 0;
  min-width: 120px;
  padding: .5rem .2rem;
  background-color: #999;
  font-weight: 700;
  border: none;
  color: #fff;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.cap {
  text-transform: capitalize;
}

.tos-button:hover {
  background-color: #000;
}

.tos-button:disabled {
  background-color: #999;
}

.main-btn[disabled] {
  cursor: initial;
  color: var(--theme-color-2);
  border-color: var(--theme-color-2);

  &:hover {
    cursor: initial;
    background: initial;
    color: var(--theme-color-2);
  }
}

.navigation-container {
  width: 45px;
  align-self: center;
  height: 18px;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin-top: -10px;
  margin-left: calc((50% - 45px) - 9.5px);
  z-index: 5;
}

.navigation-buttons {
  border-radius: 50%;
  height: 18px;
  width: 18px;
  border: 3px var(--theme-color-1) solid;
  background: white;

  &.active {
    background: var(--theme-color-1);
  }

  &:focus {
    outline: none;
  }
}