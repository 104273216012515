#shipping input {
    margin: 0px 0px 10px 0px;
    padding: 10px 20px;
}

#shipping select{
    margin-bottom: 10px;
}

#shipping hr {
    width: 100%;
    border-bottom: 1px solid grey;
}

#shipping h1 {
    margin-top: 20px;
}

#shipping button {
    padding: 15px 25px;
    margin-top: 20px;
    background: black;
    color: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 40px;
    width:fit-content;
    align-self: center;
}

#shipping label{
    margin:0;
}

#shipping button:hover {
    background: #111;
}

#shipping .address-card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    background: #ebebeb;
    border: none;
    border-radius: 10px;
    margin: 5px 20px;
    width: 90%;
    max-width: 700px;
}

#shipping .address-line{
    margin:0;
    font-size: 12px;
}

#shipping .address-card:hover {
    background: #ddd;
}

#shipping .address-card.active {
    background-color: black;
    color: white;
}

#shipping .add-btn {
    background-color: black;
    color: white;
}

#shipping .add-btn:hover {
    background-color: black;
}