.color-dropdown-toggle
{
    cursor: pointer;
    /* position: absolute; */
    /* margin-top: 50%; */
    /* transform: translate(0, -25%); */
    margin-bottom: 2rem;
    /* margin-top: 1rem; */
}

.tools-links
{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.tools-links a
{
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    font-weight: 200;
    cursor: pointer;
}

#annotate-tools
{
    margin-bottom: 1rem;
    min-height: 3rem;
    margin-top: 1rem;
    padding-top: 1rem;
}

.annotate-textarea
{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#annotate-app .btn
{
    margin-top: 0;

}

#annotate-app
{
    padding-bottom: 5rem;
}
