a.app-nav, p.app-nav
{
    text-align: center;
    font-size: 0.9rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    cursor: pointer;

}

p.cancel
{
    font-size: 2rem;
    /* margin-top: 0.8rem; */
    font-weight: 400;
}

div.cancel
{
    /* margin-top: 50%; */
    /* transform: translate(0, -50%); */
}