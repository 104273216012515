.comment
{
    background-color: #ffffff;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    /* box-shadow: 3px 3px 10px #ddd;
    border: 1px solid #eee; */
    /* border-bottom: 2px solid black; */
}

.comment p
{
    font-weight: 100;
    margin-top: 1rem;
}

.comments-wrapper
{
    background-color: #f0f0f0;


}

.comment h3
{
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.comment h3 span
{
    font-size: 1.1rem;
    color: grey;
    font-weight: 400;
}

.comment .model-link
{
    background-color: #f0f0f0;
    padding: 1rem;
    margin-top: 1rem;
}

.comment-tile p
{
    margin-bottom: 0rem;
    padding: 0rem;
}

.comment-features
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFEF6;
    border: 1px solid #D7D7D7;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0px 0px 5px 5px;
    margin-top: -0.5rem;
    font-weight: 400;
}

.comment-features .share-comment
{
    border: 1px solid #959595;
    border-radius: 2px;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;

}

#canvas .comment-features .share-comment
{
    color: #000;
    background-color: transparent;
    background: linear-gradient(to right, transparent 50%, black 50%);
    background-size: 200% 100%;
    background-position:left bottom;
}

#canvas .comment-features .share-comment:hover
{
    color: white;
    background-position:right bottom;
    transition: all 0.2s ease-in;
    text-decoration: none
}