#settings{
  max-width: 800px;
  margin-bottom: 2rem;
}

#settings h2{
  margin-bottom: 1.5rem;
  text-align: left;
}

#settings h6{
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: #5714AC;
  cursor: pointer;
}

#settings h6:hover, #settings h6:active, #settings h6:focus{
  text-decoration: underline;
}

#settings .profile-pic-actions{
  display: flex;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

#settings .profile-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#settings .profile-container .profile-pic {
  width: 9rem;
  border-radius: 50%;
  height: 9rem;
}

#settings .profile-container .edit-icon-outer-container {
  width: 2.9rem;
  border-radius: 50%;
  height: 2.9rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -45px;
  margin-bottom: 10px;
}

#settings .profile-container .edit-icon-outer-container .edit-icon-container {
  width: 2.5rem;
  border-radius: 50%;
  height: 2.5rem;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#settings .profile-container .edit-icon-outer-container .edit-icon-container:hover {
  background-color: #000;
}

#settings .profile-container .edit-icon-outer-container .edit-icon-container .edit-icon {
  width: 1.5rem;
  cursor: pointer;
}

#settings .action-buttons {
  flex: 1;
  display: flex;
  align-items: center;
}

#settings .action-buttons .upload-btn, #settings .action-buttons .remove-btn{
  background-color: #fff;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.5px;
  width: 10em;
  padding: .5rem 3rem;
  font-weight: bold;
  margin-left: 2rem;
}

#settings .action-buttons .upload-btn {
  border-color: #5714AC;
  color: #5714AC;
}

#settings .action-buttons .upload-btn:hover, #settings .action-buttons .remove-btn:active, #settings .action-buttons .remove-btn:focus {
  background-color: #5714AC;
  border-color: #5714AC;
  color: #fff;
}

#settings .action-buttons .remove-btn {
  border-color: #EA3B27;
  color: #EA3B27;
}

#settings .action-buttons .remove-btn:hover, #settings .action-buttons .remove-btn:active, #settings .action-buttons .remove-btn:focus {
  background-color: #EA3B27;
  border-color: #EA3B27;
  color: #fff;
}

#settings .save-btn-container{
 display: flex;
 justify-content: flex-end;
 margin-top: 2rem;
}

#settings .save-btn-container .save-btn{
  background-color: #5714AC;
  border-color: #5714AC;
  color: #fff;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.5px;
  padding: 1rem 2rem;
  font-weight: bold;
}

#settings .save-btn-container .save-btn:disabled{
  background-color: #737373;
}

@media (min-width:300px){
  #settings{
    max-width: 100%;
  }

  #settings .profile-pic-actions{
    flex-direction: column;
  }

  #settings .action-buttons {
    justify-content: center;
  }

  #settings .action-buttons .upload-btn {
    margin-left: 0;
  }

  #settings .profile-container {
    margin-bottom: 2rem;
  }
}

@media (min-width:600px){
  #settings{
    max-width: 800px;
  }

  #settings .profile-pic-actions{
    flex-direction: row;
  }

  #settings .action-buttons {
    justify-content: flex-start;
  }

  #settings .action-buttons .upload-btn {
    margin-left: 2rem;
  }

  #settings .profile-container {
    margin-bottom: 0;
  }
}