@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');

.main-btn[disabled] {
  cursor: initial;
  color: var(--theme-color-2);
  border-color: var(--theme-color-2);

  &:hover {
    cursor: initial;
    background: initial;
    color: var(--theme-color-2);
  }
}

// #splash-modal_container {
//   position: absolute;
//   z-index: 1;
//   top: 0; bottom: 0; left: 0; right: 0;
//   height: 100vh;
//   width: 100vw;
//   background: rgba(0, 0, 0, 0.699);
//   padding: 4rem 13%;
// }

/* Modal Container */
.splash-modal_body {
  height: 100%;
  width: 100%;
  background: white;
  position: relative;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Modal Title */
.splash-modal_body div[name="title"] {

  .logo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;

    h2 {
      text-transform: uppercase;
      color: var(--theme-color-1);
      margin-bottom: 3.5rem;
    }
  }

  p {

    margin-bottom: 0;
  }
}

/* Modal Body */
.splash-modal_body div[name="body"] {
  min-height: 300px;
  max-width: max-content;
  flex-grow: 1;
  overflow-y: auto;
  margin-block: 1.5rem;
}

/* Modal Footer */
.splash-modal_body div[name='footer'] {
  display: flex;
  flex-direction: column;

  .checkbox_container {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    
    input {
      margin-top: 5px;
      margin-right: 1rem;
    }
  }

  .main-btn_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    span {
      font-size: 12px;
      color: darkgrey;
      cursor: pointer;

      &:hover {
        color: black;
        text-decoration: underline;
      }
    }
  }
}

.main-btn {
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
  border: 2px solid var(--theme-color-1);
  padding: 0.5rem 3rem;
  font-size: 0.7rem;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  /* margin-left: 1rem; */
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2rem;
  font-size: 1rem;
  color: var(--theme-color-1);
}
  
.main-btn:hover, .main-btn:active, .main-btn:focus {
  border-color: var(--theme-color-1);
  background-color: var(--theme-color-1);
  color: #FFFFFF;
  outline: none;
}

.client-agreement {
  background-color: #ffffff;
  max-width: 451.3pt;
  padding: 72pt 72pt 72pt 72pt;

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-6>li:before {
    content: ""counter(lst-ctn-kix_list_2-6, decimal) ". "
  }

  .lst-kix_list_2-7>li:before {
    content: ""counter(lst-ctn-kix_list_2-7, lower-latin) ". "
  }

  .lst-kix_list_2-7>li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  .lst-kix_list_2-1>li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4>li:before {
    content: ""counter(lst-ctn-kix_list_2-4, lower-latin) ". "
  }

  .lst-kix_list_2-5>li:before {
    content: ""counter(lst-ctn-kix_list_2-5, lower-roman) ". "
  }

  .lst-kix_list_2-8>li:before {
    content: ""counter(lst-ctn-kix_list_2-8, lower-roman) ". "
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  .lst-kix_list_1-1>li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  .lst-kix_list_1-7>li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  .lst-kix_list_2-0>li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  .lst-kix_list_2-3>li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  .lst-kix_list_2-6>li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  .lst-kix_list_1-2>li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  .lst-kix_list_1-5>li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  .lst-kix_list_1-8>li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4>li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  .lst-kix_list_1-0>li:before {
    content: "("counter(lst-ctn-kix_list_1-0, decimal) ") "
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  .lst-kix_list_1-1>li:before {
    content: ""counter(lst-ctn-kix_list_1-1, lower-latin) ". "
  }

  .lst-kix_list_1-2>li:before {
    content: ""counter(lst-ctn-kix_list_1-2, lower-roman) ". "
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  .lst-kix_list_1-3>li:before {
    content: ""counter(lst-ctn-kix_list_1-3, decimal) ". "
  }

  .lst-kix_list_1-4>li:before {
    content: ""counter(lst-ctn-kix_list_1-4, lower-latin) ". "
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_1-0>li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  .lst-kix_list_1-6>li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_1-7>li:before {
    content: ""counter(lst-ctn-kix_list_1-7, lower-latin) ". "
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  .lst-kix_list_1-3>li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  .lst-kix_list_1-5>li:before {
    content: ""counter(lst-ctn-kix_list_1-5, lower-roman) ". "
  }

  .lst-kix_list_1-6>li:before {
    content: ""counter(lst-ctn-kix_list_1-6, decimal) ". "
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  .lst-kix_list_2-0>li:before {
    content: ""counter(lst-ctn-kix_list_2-0, decimal) ". "
  }

  .lst-kix_list_2-1>li:before {
    content: ""counter(lst-ctn-kix_list_2-1, lower-latin) ". "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_2-5>li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  .lst-kix_list_2-8>li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  .lst-kix_list_1-8>li:before {
    content: ""counter(lst-ctn-kix_list_1-8, lower-roman) ". "
  }

  .lst-kix_list_2-2>li:before {
    content: ""counter(lst-ctn-kix_list_2-2, lower-roman) ". "
  }

  .lst-kix_list_2-3>li:before {
    content: ""counter(lst-ctn-kix_list_2-3, decimal) ". "
  }

  .lst-kix_list_2-2>li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td,
  table th {
    padding: 0
  }

  .c20 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 225pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c16 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 229.5pt;
    border-top-color: #000000;
    border-bottom-style: solid
  }

  .c1 {
    margin-left: 14.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 14.4pt;
    height: 11pt
  }

  .c4 {
    background-color: #ffffff;
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c3 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
  }

  .c34 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 7.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: -3.2pt
  }

  .c19 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 14.4pt
  }

  .c32 {
    margin-left: 14.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center;
    margin-right: 14.4pt
  }

  .c25 {
    margin-left: 14.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 1.3pt
  }

  .c21 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: -3.2pt
  }

  .c5 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman"
  }

  .c10 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal
  }

  .c18 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c23 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman"
  }

  .c13 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 14.4pt
  }

  .c15 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c37 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c42 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c17 {
    background-color: #ffffff;
    color: #555555;
    font-weight: 400;
    font-size: 12pt;
    font-family: "Times New Roman"
  }

  .c8 {
    font-size: 12pt;
    font-family: "Times New Roman";
    color: #000000;
    font-weight: 400
  }

  .c45 {
    color: #000000;
    font-weight: 400;
    font-size: 11pt;
    font-family: "Calibri"
  }

  .c2 {
    font-size: 12pt;
    font-family: "Times New Roman";
    color: #000000;
    font-weight: 700
  }

  .c27 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
  }

  .c22 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 700
  }

  .c39 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none
  }

  .c35 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400
  }

  .c44 {
    margin-left: 72pt;
    text-indent: -72pt
  }

  .c31 {
    vertical-align: baseline;
    font-style: italic
  }

  .c46 {
    font-weight: 400;
    font-family: "Times New Roman"
  }

  .c40 {
    color: inherit;
    text-decoration: inherit
  }

  .c36 {
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c14 {
    padding: 0;
    margin: 0
  }

  .c11 {
    vertical-align: baseline;
    font-style: normal
  }

  .c47 {
    color: #000000;
    font-size: 11pt
  }

  .c29 {
    background-color: #ffffff
  }

  .c28 {
    height: 0pt
  }

  .c43 {
    margin-left: 18pt
  }

  .c33 {
    text-indent: -36pt
  }

  .c41 {
    margin-left: 14.4pt
  }

  .c9 {
    height: 11pt
  }

  .c6 {
    height: 10.8pt
  }

  .c26 {
    background-color: #ffff00
  }

  .c38 {
    margin-right: 1.3pt
  }

  .c30 {
    margin-left: 36pt
  }

  .c12 {
    text-decoration: none
  }

  .c24 {
    height: 7.2pt
  }

  .c7 {
    padding-left: 0pt
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}