#canvas-wrapper
{
    height: 50vh;
}

.human-img
{
    position: absolute;
    /* top: 0; */
    bottom: 3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 25vh;
}

.human-img-1
{

    height: 25vh;
}

.human-img-2
{
    height: 30vh;
}

.dimensions-img-wrapper
{
    height: 50vh;
    width: 50vh;
    position: relative;
}

.front-view
{
    position: absolute;
    /* top: 0; */
    bottom: 3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: gray;
    opacity: 0.6;
}

.side-view
{
    position: absolute;
    /* top: 0; */
    bottom: 3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: gray;
    opacity: 0.6;
}

.dimensions-number-input:focus{
    box-shadow: none;
}

@media (max-width: 500px){
    .dimensions-img-wrapper{
        width:40vh;
    }
    .col-3{
        padding-left:20px;
    }
}