@font-face {
    font-family: 'rand black italic';
    src: url("./fonts/Rand-Black-Italic.otf");
}
@font-face {
    font-family: 'rand black';
    src: url("./fonts/Rand-Black.otf");
}
@font-face {
    font-family: 'rand bold italic';
    src: url("./fonts/Rand-Bold-Italic.otf");
}
@font-face {
    font-family: 'rand bold';
    src: url("./fonts/Rand-Bold.otf");
}
@font-face {
    font-family: 'rand heavy italic';
    src: url("./fonts/Rand-Heavy-Italic.otf");
}
@font-face {
    font-family: 'rand heavy';
    src: url("./fonts/Rand-Heavy.otf");
}

@font-face {
    font-family: 'rand italic';
    src: url("./fonts/Rand-Italic.otf");
}
@font-face {
    font-family: 'rand light italic';
    src: url("./fonts/Rand-Light-Italic.otf");
}
@font-face {
    font-family: 'rand light';
    src: url("./fonts/Rand-Light.otf");
}
@font-face {
    font-family: 'rand medium italic';
    src: url("./fonts/Rand-Medium-Italic.otf");
}
@font-face {
    font-family: 'rand medium';
    src: url("./fonts/Rand-Medium.otf");
}
@font-face {
    font-family: 'rand regular';
    src: url("./fonts/Rand-Regular.otf");
}
@font-face {
    font-family: 'rand thin italic';
    src: url("./fonts/Rand-Thin-Italic.otf");
}
@font-face {
    font-family: 'rand thin';
    src: url("./fonts/Rand-Thin.otf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.ElementsApp input
{
  font-size: 1.5rem;

}

.ElementsApp .CardField
{
  margin-top: 1rem;
  margin-bottom: 1rem;

}

p, div, button, span
{
    font-family: 'rand regular';
}

h1, h2, h3
{
    font-family: 'rand heavy';
}

h4, h5, h6
{
    font-family: 'rand bold';
}

.navbar-brand
{
    font-family: 'rand bold';
  
}

.makeStyles-root-1 * {
  font-family: 'rand regular' !important;
}

body {
  color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --theme-color-1: rgba(87, 20, 172, 1);
  --theme-color-2: rgba(87, 20, 172, 0.3);
  --theme-color-3: rgba(158, 131, 191, 0.1);
  --theme-color-with-opacity: rgba(158, 131, 191, 0.3);
  --theme-color-1-hex: #5714ac;
}