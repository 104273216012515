#account
{
    padding-top: 5rem;
    padding-bottom: 5rem;

}

#canvas-manager
{
    padding-top: 2rem;
}

#canvas-manager h2
{
    text-align: left;
    font-size: 2rem;
    margin-bottom: 1rem;
}

#account .canvas-widget>div
{
    padding: 1rem;
}


#account .canvas-widget .img-container
{
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1rem;
}

#account .canvas-widget .img-container:hover .canvas-image
{
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
  filter: blur(2px);
}

#account .canvas-widget .canvas-image
{
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

#account .canvas-widget .img-container:hover .options-container
{
   opacity: 1;
}

#account .canvas-widget .img-container .options-container{
    display: flex;
    /* position: absolute; */
    width: 5rem;
    justify-content: center;
    align-items: center;
    opacity: 0;
    width: inherit;
}

#account .canvas-widget .options-container .options{
    background-color: #000;
    color: #fff;
    border-radius: 1rem;
    padding: .5rem 1rem;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    font-size: 0.7rem;
    width: 10em;
    text-align: center;
}

#profilePic .editWrapper
{
    background-color: #333;
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
    border-radius: 5rem;
    border: 5px solid white;
    transition: ease-out 0.2s;

}

#profilePic .editWrapper label
{
    margin-bottom: 0rem;
    padding: 1rem;
    cursor: pointer;

}

#profilePic .editWrapper img
{
    height: 1.5rem;
}

#profilePic .editWrapper:hover
{
    background-color:black;
}

#account .profileDetails
{
    text-align:  center;
}

#account .profileDetails h1
{
    text-align :center; 
    font-weight:500;
    font-size:3rem;
    margin-bottom:0.5rem;
}

#account .new-project-btn-phone, #account .new-project-btn{
    background-color: #fff;
    padding: 0.7rem 2.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-style: solid;
    border-radius: 2rem;
    border-color: #5714AC;
}

#account .new-project-btn-phone:focus, #account .new-project-btn-phone:hover, #account .new-project-btn-phone:active, #account .new-project-btn:hover, #account .new-project-btn:active, #account .new-project-btn:focus{
    outline: none;
    border-style: solid;
    background-color: #5714AC;
    color: #fff;
}

#account .create-project-btn-container{
    display: flex;
    padding: 0;
}

#account .title-btn-container{
    display: flex;
    justify-content: space-between;
}

#canvas-manager h2
{
    text-align: center;
}

#canvas-manager .title-filter-container{
    display: flex;
    align-items: center;
}

#canvas-manager .action-icon{
    color: #808080;
    cursor: pointer;
    font-size: 1.5rem;
    padding-left: 1rem;
}

#canvas-manager .action-icon:hover{
    color: #5714AC;
    font-size: 1.6rem;
}

@media (min-width:300px){
    #account .create-project-btn-container{
        justify-content:center;
    }

    #account .new-project-btn{
        display: none;
    }

    #account .title-btn-container{
        justify-content: center;
    }
}

@media (min-width:600px){
    #account .create-project-btn-container{
        justify-content: flex-start;
    }

    #account .new-project-btn{
        display: block;
    }

    #account .new-project-btn-phone{
        display: none;
    }

    #account .title-btn-container{
        justify-content: space-between;
    }
}

@media (min-width:992px){
    #account .profileDetails
    {
        text-align:  left;
    }
    
    #account .profileDetails h1
    {
        text-align :left; 
    }

    
    #canvas-manager h2
    {
        text-align: left;
    }
}