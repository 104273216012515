/* ------ HERO ------ */
#hero{
  display: flex;
  height: 100vh;
  /* height: -moz-available; */
  flex-wrap: wrap;
  background-color: #F8F8F8;
  /* background-size: 0 0; */
}

#hero .introduction-text{
  flex: 1;
  display: flex;
}

#hero .logo-container{
  flex: 1;
  display: flex;
}

#hero .btn{
  border:none;
  background-color: #000000;
  color: #FFFFFF;
  margin-top: 15px;
  text-transform: none;
}

#hero h6{
  font-size: 24px;
  font-weight: 900;
  font-family: sofia-pro,sans-serif;
  color:#000000;
}

#hero h1{
  font-size: 64px;
  font-family: sofia-pro, sans-serif;
  font-weight: bold;
  line-height: 55px;
  color:#000000;
}

#hero .text-container{
  max-width: 493px;
  margin: auto;
}

#hero .logo{
  width:50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  border-radius:50%;
}

/* SUBMIT IDEA */
#submit-idea{
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  background-color: #FFFFFF;
}

#submit-idea .text-container{
  max-width: 500px;
  margin: auto;
}

#submit-idea h1{
  font-size: 65px;
  font-weight: bold;
  font-family: sofia-pro, sans-serif;
  margin-bottom: 20px;
}

#submit-idea h6{
  font-size: 24px;
  line-height: 24px;
  font-family: sofia-pro, sans-serif;
}

#submit-idea img{
  margin-bottom: auto;
  margin-top: auto;
  width:90%;
  margin-right: auto;
}

#submit-idea .submit-idea-image-conatiner{
  display: flex;
  flex: 1;
}

#submit-idea .text-container-wrapper{
  display: flex;
  flex: 1;
}

/* ------ CALL FOR VISIONARY ------ */
#call-for-visionary{
  display: flex;
  height: fit-content;
  background-color: #000000;
  color: #FFFFFF;
  min-height: 100vh;
}

#call-for-visionary h1{
  font-size: 80px;
  padding: 40px;
  margin: auto;
  font-family: sofia-pro, sans-serif;
  font-weight: bold;
}

/* DESIGN GUIDELINES */
#design-guidelines{
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  background-color: #FFFFFF;
}

#design-guidelines .introduction-text{
  flex: 1;
  display: flex;
}

#design-guidelines .text-container{
  max-width: 590px;
  margin: auto;
  margin-right: 10px;
  text-align: right;
}

#design-guidelines h1{
  font-size: 48px;
  font-family: sofia-pro,sans-serif;
  font-weight: 900;
  line-height: 48px;
  margin-bottom: 20px;
}

#design-guidelines h4{
  font-size: 36px;
  font-family: sofia-pro,sans-serif;
  font-weight: 900;
  line-height: 36px;
}

#design-guidelines h6{
  font-size: 18px;
  font-family: sofia-pro,sans-serif;
  line-height: 18px;
  margin-bottom: 10px;
}

/* SUBMISSION DEADLINE */
#submission-deadline{
  display: flex;
  height: 100vh;
  background-color: #000000;
  color: #FFFFFF;    
  flex-direction: column;
  padding: 20px;
}

#submission-deadline h1{ 
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  line-height: 72px;
  font-family: sofia-pro, sans-serif;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

#submission-deadline h3{ 
  font-size: 48px;
  line-height: 48px;
  font-family: sofia-pro, sans-serif;    
  font-weight: bold;
}

#submission-deadline h6{   
  font-size: 18px;
  line-height: 18px;
  font-family: sofia-pro, sans-serif;
  margin-bottom: 10px;
}

#submission-deadline .deadline-container{
  flex:1;
  margin:20px; 
  text-align:center;
}

/* JURY */
#jury{
  display: flex;
  min-height: 800px;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #FFFFFF;
}

#jury h1{
  font-size: 48px;
  font-weight: 900;
  font-family: sofia-pro, sans-serif;
  text-align: center;
  margin: auto;
  margin-bottom: 0;
  margin-top: 10px;
}

#jury p{
  font-size: 12px;
  font-weight: normal;
}

/* ------ CRITERIA ------ */
#criteria{
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  background-color: #F8F8F8;
  flex-direction: column;
}

#criteria h1{
  margin: auto;
  font-size: 48px;
  line-height: 48px;
  font-weight: 900;
  font-family: sofia-pro, sans-serif;
}

#criteria h3{
  font-size: 36px;
  font-family: sofia-pro,sans-serif;
  font-weight: 900;
}

#criteria p{
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  font-family: sofia-pro,sans-serif;
  font-weight: 900;
}

#criteria .criteria-container{
  flex:1;
  margin:20px; 
  text-align:center;
}

/* FOOTER */
#section-last {
  background-image: url("../images/contest/table.png");
  height: 100vh;
  /* background-image: url('../images/ecomobile.png'); */
  background-repeat: no-repeat;
  background-position: center;
 /* Center the image */
  background-size: cover;
  background-color: #FFFFFF;
}

#section-last .container{
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  text-align: center;
}

#section-last a {
  color:#000000;
  text-decoration: underline;
}

#section-last .text-list{
  margin-bottom:'10%';
  font-size:48px; 
  display:flex;
  flex-direction:row;
  flex-wrap:wrap; 
  justify-content:space-between;
}

@media (max-width: 480px){
  #contest-page-container{
    width: fit-content;
  }
  /* HERO */
  #hero{
    flex-direction: column-reverse;
    /* width: max-content; */
    height: fit-content;
    min-height: 100vh;
  }
  #hero h1{
    font-size: 40px;
    text-align: center;
    line-height: 40px;
  }
  #hero h6{
    text-align: center;
  }
  #hero .text-container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
  #hero .logo-container{
    margin-top: 30px;
  }
  #hero img{
    margin-top: 40px;
    margin-bottom: 20px;
    margin: auto;
  }
  #hero .btn{
    margin-bottom: 40px;
  }
  #hero .logo{
    width: auto;
  }
  /* SUBMIT IDEA */
  #submit-idea{
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* width: max-content; */
  }
  #submit-idea h1{
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 40px;
  }
  #submit-idea h6{
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 18px;
  }
  #submit-idea img{
    margin-bottom: 20px;
  }
  #submit-idea .text-container{
    margin-top: 0;
  }
  /* DESIGN GUIDELINES */
  #design-guidelines .text-container{
    padding: 10px;
    /* text-align: center; */
  }
  #design-guidelines h6{
    /* text-align: left; */
  }
  /* SUBMISSION DEADLINE */
  #submission-deadline{
    height: fit-content;
  }
  #submission-deadline h1{
    max-width: 300px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
  }
  #submission-deadline h3{ 
    font-size: 30px;
    line-height: 30px;
    font-family: sofia-pro, sans-serif;
    font-weight: bold;
  }
  
  #submission-deadline h6{   
    font-size: 14px;
    line-height: 14px;
    font-family: sofia-pro, sans-serif;
    margin-top: 30px;
  }
  /* CALL FOR VISIONARY */
  #call-for-visionary{
    height: fit-content;
    min-height: fit-content;
  }
  #call-for-visionary h1{
    font-size: 40px;
    /* width: 500px; */
    margin: auto;
    /* text-align: center; */
  }
  #jury{
    height: fit-content;
  }
  #criteria{
    height: fit-content;
  }
  /* FOOTER */
  #section-last {
    background-image: url("../images/contest/image_phone.png");
    /* height: 100vh; */
    /* background-image: url('../images/ecomobile.png'); */
    background-repeat: no-repeat;
    background-position: center;
  /* Center the image */
    max-height: 500px!important;
    background-size: cover;
    font-size: 30px;
    justify-content: center;
  }
  #section-last a{
    width:100%;
    font-size: 40px;
    color:#FFFFFF;
  }
  #section-last .register{
    font-size:40px;
    color: #FFFFFF;
  }

  #section-last .text-list{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
  }
}



@media (max-width: 1200px){
  #contest-page-container{
    width: fit-content;
  }
  /* HERO */
  #hero{
    flex-direction: column-reverse;
    /* width: max-content; */
    min-height:100vh;
  }
  #hero h1{
    font-size: 40px;
    text-align: center;
    line-height: 40px;
  }
  #hero h6{
    text-align: center;
  }
  #hero .text-container{
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
  #hero .logo-container{
    margin-top: 30px;
    flex: 1;
  }
  #hero img{
    margin-top: 60px;
    margin-bottom: 20px;
  }
  #hero .btn{
    margin-bottom: 40px;
  }
  #hero .logo{
    width: auto;
    margin: auto;
    margin-bottom: 30px;
  }

  #section-last a{
    width: fit-content;
  }

  /* SUBMIT IDEA */
  #submit-idea{
    /* height: fit-content; */
    height: auto;

    display: flex;
    flex-direction: column;
    /* width: max-content; */
  }
  #submit-idea h1{
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 40px;
  }
  #submit-idea h6{
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 18px;
  }
  #submit-idea img{
    margin:auto;
  }
  #submit-idea .text-container{
    margin-top: 0;
  }
  #submit-idea .submit-idea-image-conatiner{
    display: flex;
    flex: inherit;
  }
  
  #submit-idea .text-container-wrapper{
  }
  /* DESIGN GUIDELINES */
  #design-guidelines .text-container{
    padding: 10px;
    /* text-align: center; */
  }
  #design-guidelines h6{
  }
  #design-guidelines .logo-container{
    display: flex;
    width:100%;
  }
  #submission-deadline .deadline-container{
    min-width: 250px;
  }
  #submission-deadline h6{
    margin-top: 10px;
  }
  #criteria .criteria-container{
    margin-top: 35px;
    margin-bottom: 35px;
  }
  #section-last {
    background-repeat: no-repeat;
    background-position: center;
    max-height: 950px;
    background-size: cover;
    font-size: 30px;
  }

  #section-last .container{
    justify-content: center;
  }

  #section-last .text-list{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    flex: 4;
    justify-content: space-evenly;
  }
}


