#additional-details .card{
    width:18rem;
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(39, 70, 132, 0.2);
    cursor: pointer;
}

#additional-details h5{
    margin-top: 0;
}