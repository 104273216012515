
div.auth-wrapper
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* transform: translate(0, -50%) */
    border: 1px solid transparent;
    border-radius: 10px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: "hidden"
}

#forgot-password-btn
{
    margin-top:0.5rem;
    text-transform:none;
    font-weight:bold;
    font-size:1.2rem;
    color:black;
    background-color:white;
    border:none;
    padding-left:0;
    padding-right:0;
    text-align:center;
}

#login-btn
{
    margin-top:2rem;
    text-transform:none;
    font-weight:bold;
    font-size:1.2rem;
    padding-left: 0;
    padding-right: 0;
    min-width: 10rem;
    display: flex;
    flex-basis: 0;
    justify-content: space-evenly;
    align-items: center;
    
}

#google-btn{
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin: auto;
    margin-top: 2rem;
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: 1px solid #fafafa;
    border-radius: .25rem;
}

#facebook-btn{
    background-color: #3c5898;
    color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin: auto;
    margin-top: 2rem;
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    border-radius: .25rem;
    
}

@media (min-width:992px){
    div.auth-wrapper
    {
        margin-top:2rem;
        border: 1px solid #ccc;
        border-radius: 10px;
    }    

}