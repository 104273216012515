/************************************************************************************/
/************************************************************************************/
/* HERO */
#hero{
  background-image: url("../../images/sustainability-page/hero_web.png");
  }

/* FULL_WIDTH_IMAGE */
.full-width-image.featured-work{
  background-image: url("../../images/sustainability-page/featured_work_web.png");

}

/* FULL_WIDTH_IMAGE */
.full-width-image.featured-designer{
  background-image: url("../../images/sustainability-page/footer_web.png");

}

.full-width-image.featured-designer h1{
  color: black;
  text-align: left;
  margin-left: 2rem;
}
.full-width-image.featured-designer h6{
  color: black;
  text-align: left;
  margin-left: 2rem;
}

.full-width-image.featured-work h1{
  text-align: left;
  margin-left: 2rem;
}
.full-width-image.featured-work h6{
  text-align: left;
  margin-left: 2rem;
}

.full-width-image.featured-designer .section4-container{
  background-color    : rgba(0,0,0,0.0);
}

.full-width-image.featured-work .section4-container{
  background-color    : rgba(0,0,0,0.0);
}
  
/* Footer */
#footer{
  background-image: url("../../images/sustainability-page/footer_web.png");
}


@media (min-width: 300px){
    #hero{
      background-image: url("../../images/sustainability-page/hero_phone.png");
    }

    .full-width-image.featured-work{
      background-image: none
    }

    .full-width-image.featured-designer{
      background-image: none;
    
    }

    #footer{
      background-image: url("../../images/sustainability-page/footer_phone.png");
    }
  }

  
  @media (min-width: 600px){
    #hero{
      background-image: url("../../images/sustainability-page/hero_tab.png");
    }

    .full-width-image.featured-work{
      background-image: url("../../images/sustainability-page/featured_work_tab.png");
    }

    .full-width-image.featured-designer{
      background-image: url("../../images/sustainability-page/footer_web_tab.png");
    
    }
    #footer{
      background-image: url("../../images/sustainability-page/footer_web_tab.png");
    }
    .full-width-image.featured-work h6{
      max-width: 40%;
    }
  }
  
  @media (min-width: 1000px){
    #hero{
      background-image: url("../../images/sustainability-page/hero_web.png");
    }

    .full-width-image.featured-work{
      background-image: url("../../images/sustainability-page/featured_work_web.png");
    }

    .full-width-image.featured-designer{
      background-image: url("../../images/sustainability-page/footer_web.png");
    
    }
    #footer{
      background-image: url("../../images/sustainability-page/footer_web.png");
    }

    .full-width-image.featured-designer h6{
      max-width: 20%;
    }

    .full-width-image.featured-work h6{
      max-width: 20%;
    }
  }


/************************************************************************************/
/************************************************************************************/

  
  