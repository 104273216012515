
div.pre-canvas-h2
{
    margin-top: 5rem;
    margin-bottom: 5rem;
    /* transform: translate(0, -50%) */
}

img.canvas-image
{
    height: 10em;
    width: auto;
}

#canvas
{
    padding-bottom: 5rem;
}

#canvas h2
{
    margin-top: 3rem;
}

#canvas h6
{
    color: rgb(190, 102, 19);
}

.collab-button.btn
{
    margin-top: 0rem;
    border-radius: 0px;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 0.5rem;
    margin-right: 1rem;
    width: 100%;
    min-width: 0;
}

.collab-button-wrapper
{
    padding: 0.5rem;
}

#comment-box
{
    background-color: rgb(233, 233, 233);
    padding: 1rem;
}

#comment-box .btn
{
    margin-top: 1rem;
    min-width: 0;
    color: black;
    background-color: white;
    border-radius: 0px;
    padding: 0.5rem;
    padding-left: 1rem;
    width: 100%;
    padding-right: 1rem;
    text-transform: capitalize;
    font-weight: 100;
}

div.collab-button-wrapper p
{
    text-align: center;
}