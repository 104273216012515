#tools-modal
{
    font-weight: 400;
    
}

#tools-modal .modal-content
{
    font-weight: 400;
    padding: 0;
}

#tools-modal .modal-content .btn
{
    
    /* margin-left: 8.33%; */
    margin-top: 0;
}

#tools-modal .modal-footer
{
    
    border-top-color: transparent;
}

#tools-modal .modal-header
{
    
    border-bottom-color: transparent;
}