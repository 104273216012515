
body
{
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#naya-navbar
{
    margin-bottom: 2rem;
}

#naya-navbar .navbar-brand
{
    font-family: bigmoore, serif;
    font-size: 2rem;
}

h2{

    font-family: bigmoore, serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 1.9rem;
    margin-bottom: 5rem;
}

h4{

    font-size: 1.2rem;
}


.question-h2
{
    margin-bottom: 0rem;
}

.question-h4
{
    margin-bottom: 4rem;
}

h2 img
{
    width: 1.5rem;
    height: 1.5rem;
}

button.btn
{
    color: white;
    background-color: black;
    /* padding: 1rem; */
    /* font-size: 0.8rem; */
    padding-top: 0.8rem;

    padding-bottom: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    /* border-radius: 50px; */
    margin-top: 6.5rem;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.navbar-continue .btn
{
    margin: 0;
    margin-top: 50%;
    transform: translate(0, -50%);
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 3rem; */
}

p.skip-button
{
    width: 100%;
    /* margin-left: auto;
    margin-right: auto; */
    text-align: center;
    font-size: 0.9rem;

}

div.vertical-center
{
    position: absolute;
    margin-top: 50vh;
    transform: translate(0, -25vh);
}

p.landing-option
{
    text-align: center;
}

img.landing-option-img
{
    border-radius: 100%;
}

.question-other-input
{
    color: black;
    font-size: 0.85rem;
    font-weight: 700;
    text-align: center;
    padding: 0rem;
    border: 1px solid black;
    border-radius: 0;
}

.navbar-continue
{
    display: none;
}

@media (min-width: 992px) { 
    

    .navbar-continue
    {
        display: inline-block;
    }
 }

 @media (min-width: 768px){
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 20.333333%;
    }
}

 p.skip-button
{
    /* width: 100%; */
    /* margin-left: auto;
    margin-right: auto; */
    text-align: center;
    /* font-size: 0.6rem; */
    cursor: pointer;


}

p.skip-button:hover
{
    text-decoration: underline;
}

.dropdown-menu {
    max-height: 60vh;
    overflow-y: auto;
    right: 0;
    left: auto;
}

/* width */
.dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .dropdown-menu::-webkit-scrollbar-thumb {
    background: #CCCCCC; 
  }
  
  /* Handle on hover */
  .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #CCCCCC; 
  }

  /* .prompts .modal-dialog{
    position: absolute; 
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: fit-content;
  } */