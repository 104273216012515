.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  #payment
  {
    margin-top: 5rem;
    /* margin-top: 50vh; 
    transform: translate(0%, -50%); */
  }

  #payment > div
  {
    /* margin-top: 50%; 
    transform: translate(0%, -50%); */
  }

  #payment .content-wrapper
  {
    background-color: #eee;
    color: black;
    /* border: 2px solid black; */
    padding: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    min-height: 50vh;

  }

  #payment h1
  {
    font-family: bigmoore, sans-serif;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  #payment .prices
  {
    text-align: right;

  }

  #payment .prices>div
  {
    position: absolute;
    top: 50%;
    right: 1rem;
    /* margin-top: 50%; */
    transform: translate(0, -50%);

  }

  #payment .prices h4
  {
    color: #444;
    text-decoration-line: line-through;
    font-size: 1.2rem;
  }

  #payment .prices h3
  {
    color: #000;
    font-weight: bold;
    font-size: 2rem;
  }


  #payment .product-desc h5
  {
    color: #000;
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
  }

  
  #payment .product-desc p
  {
    color: #444;
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-size: 0.85rem;
  }


  #payment .product
  {
      /* box-shadow: 10px 10px 10px black; */
      margin-bottom: 2rem;
      padding: 1rem;
      border: 1px solid black;
      margin-top: 4rem;
  }